import { withIdC as imported206_withIdC } from "./threadThrough";
import * as t from "io-ts";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { postRelatedC as imported39_postRelatedC, postRelatedC as imported38_postRelatedC, relatedRfpLinkC as imported9_relatedRfpLinkC, relatedOfferingLinkC as imported9_relatedOfferingLinkC } from "./relatedContent";
import { markdownC } from "../../codecs/markdown";
import { mediaC as imported53_mediaC, mediaC as imported52_mediaC, imageUploadResponseC as imported5_imageUploadResponseC } from "./media";
import { LocalDateC } from "../../codecs/localDate";

export const roadShowC = t.type({
  mp3MediaId: t.number,
  duration: t.number,
  skippable: t.boolean,
  audio: t.boolean,
  title: t.string,
  disclaimer: markdownC,
  startAvailableDate: optionFromNullable(LocalDateC),
  endAvailableDate: optionFromNullable(LocalDateC),
  availableToIssuer: t.number,
  roadShowOrder: t.number,
  hidden: t.boolean
});
export type RoadShowC = typeof roadShowC;
export type RoadShow = t.TypeOf<RoadShowC>;


export const roadShowSlideC = t.type({
  roadShowId: t.number,
  mediaId: t.number,
  startTime: t.number,
  participants: optionFromNullable(t.string),
  media: imported53_mediaC
});
export type RoadShowSlideC = typeof roadShowSlideC;
export type RoadShowSlide = t.TypeOf<RoadShowSlideC>;


export const roadShowDataC = t.type({
  show: roadShowC,
  mp3: imported52_mediaC,
  slides: t.readonlyArray(imported206_withIdC(roadShowSlideC)),
  offeringIds: t.readonlyArray(t.number),
  rfpIds: t.readonlyArray(t.number)
});
export type RoadShowDataC = typeof roadShowDataC;
export type RoadShowData = t.TypeOf<RoadShowDataC>;


export const roadShowPostRelatedContentC = t.type({
  offerings: imported38_postRelatedC(imported9_relatedOfferingLinkC),
  rfps: imported39_postRelatedC(imported9_relatedRfpLinkC)
});
export type RoadShowPostRelatedContentC = typeof roadShowPostRelatedContentC;
export type RoadShowPostRelatedContent = t.TypeOf<RoadShowPostRelatedContentC>;


export const roadShowPostBodyC = t.type({
  id: t.number,
  title: t.string,
  hidden: t.boolean,
  startAvailableDate: optionFromNullable(LocalDateC),
  endAvailableDate: optionFromNullable(LocalDateC),
  relatedContent: roadShowPostRelatedContentC,
  esgRelated: t.boolean
});
export type RoadShowPostBodyC = typeof roadShowPostBodyC;
export type RoadShowPostBody = t.TypeOf<RoadShowPostBodyC>;


export const roadShowProgressPostC = t.type({
  issuerId: t.number,
  roadShowId: t.number,
  sessionId: t.string,
  furthestSeek: t.number,
  slidesViewed: t.number
});
export type RoadShowProgressPostC = typeof roadShowProgressPostC;
export type RoadShowProgressPost = t.TypeOf<RoadShowProgressPostC>;


export const roadShowSlidePostC = t.type({
  startTime: t.number,
  participants: optionFromNullable(t.string),
  mediaId: optionFromNullable(t.number)
});
export type RoadShowSlidePostC = typeof roadShowSlidePostC;
export type RoadShowSlidePost = t.TypeOf<RoadShowSlidePostC>;


export const roadShowSlideMediaPostC = t.type({
  slide: roadShowSlidePostC,
  media: imported5_imageUploadResponseC
});
export type RoadShowSlideMediaPostC = typeof roadShowSlideMediaPostC;
export type RoadShowSlideMediaPost = t.TypeOf<RoadShowSlideMediaPostC>;


export const roadShowViewC = t.type({
  roadShowId: t.number,
  userId: optionFromNullable(t.number),
  sessionId: optionFromNullable(t.string),
  furthestSeek: t.number,
  slidesViewed: t.number
});
export type RoadShowViewC = typeof roadShowViewC;
export type RoadShowView = t.TypeOf<RoadShowViewC>;


