import { WithStatusCU as imported221_WithStatusCU, WithStatusCU as imported222_WithStatusCU, withIdC as imported204_withIdC, withIdC as imported205_withIdC, WithStatusCU as imported225_WithStatusCU, WithStatusCU as imported223_WithStatusCU, WithStatusCU as imported224_WithStatusCU, WithStatusCU as imported220_WithStatusCU } from "./threadThrough";
import { readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { offeringParticipantC as imported15_offeringParticipantC } from "./offeringParticipant";
import { programRatingC as imported12_programRatingC, issuerRatingC as imported12_issuerRatingC } from "./ratingBase";
import { RatingAgencyCU as imported33_RatingAgencyCU, RatingAgencyCU as imported34_RatingAgencyCU } from "../domaintables/ratingAgencies";
import { ratingWithRelatedContentC as imported19_ratingWithRelatedContentC, ratingWithRelatedContentC as imported20_ratingWithRelatedContentC } from "./rating";
import { taggedContentC as imported124_taggedContentC, taggedContentC as imported123_taggedContentC, taggedContentC as imported125_taggedContentC } from "./taggedContent";
import { bondProgramC as imported15_bondProgramC } from "./bondProgramBase";
import { bondProgramWithRelatedContentC as imported3_bondProgramWithRelatedContentC } from "./bondProgram";
import { subscribedC as imported23_subscribedC, subscribedC as imported24_subscribedC } from "./subscribed";
import { customPageDataOC as imported4_customPageDataOC } from "./customPages";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { roadShowDataC as imported19_roadShowDataC } from "./roadshow";
import { bondOfferingWithRelatedContentC as imported9_bondOfferingWithRelatedContentC, bondOfferingWithRelatedContentC as imported10_bondOfferingWithRelatedContentC } from "./bondOffering";
import { clientTextItemC as imported15_clientTextItemC, clientTextItemC as imported16_clientTextItemC } from "./clientTextItem";

export const bondsSsrDataC = t.type({
  offerings: t.readonlyArray(imported23_subscribedC(imported220_WithStatusCU(imported123_taggedContentC(imported9_bondOfferingWithRelatedContentC)))),
  roadshows: t.readonlyArray(imported204_withIdC(imported124_taggedContentC(imported19_roadShowDataC))),
  participants: t.readonlyArray(imported221_WithStatusCU(imported15_offeringParticipantC)),
  generalInfo: optionFromNullable(imported15_clientTextItemC),
  issuerRatings: t.readonlyArray(t.tuple([imported33_RatingAgencyCU, readonlyNonEmptyArrayC(imported222_WithStatusCU(imported19_ratingWithRelatedContentC(imported12_issuerRatingC)))])),
  programRatings: t.readonlyArray(t.tuple([imported205_withIdC(imported15_bondProgramC), readonlyNonEmptyArrayC(t.tuple([imported34_RatingAgencyCU, readonlyNonEmptyArrayC(imported223_WithStatusCU(imported20_ratingWithRelatedContentC(imported12_programRatingC)))]))])),
  programs: t.readonlyArray(imported224_WithStatusCU(imported3_bondProgramWithRelatedContentC)),
  customPages: t.readonlyArray(imported4_customPageDataOC)
});
export type BondsSsrDataC = typeof bondsSsrDataC;
export type BondsSsrData = t.TypeOf<BondsSsrDataC>;


export const archivedBondsSsrDataC = t.type({
  offerings: t.readonlyArray(imported24_subscribedC(imported225_WithStatusCU(imported125_taggedContentC(imported10_bondOfferingWithRelatedContentC)))),
  generalInfo: optionFromNullable(imported16_clientTextItemC)
});
export type ArchivedBondsSsrDataC = typeof archivedBondsSsrDataC;
export type ArchivedBondsSsrData = t.TypeOf<ArchivedBondsSsrDataC>;


