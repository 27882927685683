import * as t from "io-ts";
import { offeringParticipantWithTypeC as imported1_offeringParticipantWithTypeC } from "./offeringParticipant";
import { mediaC as imported89_mediaC } from "./media";
import { taggedContentC as imported215_taggedContentC, taggedContentC as imported217_taggedContentC, taggedContentC as imported216_taggedContentC } from "./taggedContent";
import { bankC as imported21_bankC } from "./bank";
import { bondOfferingC as imported31_bondOfferingC } from "./bondOfferingBase";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { roadShowDataC as imported33_roadShowDataC } from "./roadshow";
import { relatedOfferingParticipantLinkC as imported19_relatedOfferingParticipantLinkC } from "./relatedContent";
import { withIdC as imported297_withIdC, withIdC as imported298_withIdC, hasManyLinkC as imported175_hasManyLinkC, withIdC as imported296_withIdC, withIdC as imported299_withIdC } from "./threadThrough";
import { issuerWithAddressC as imported9_issuerWithAddressC } from "./issuer";

export const investorPortalOfferingC = t.type({
  offering: imported296_withIdC(imported215_taggedContentC(imported31_bondOfferingC)),
  issuer: imported9_issuerWithAddressC,
  bank: optionFromNullable(imported21_bankC),
  subscribed: t.boolean,
  documents: t.readonlyArray(imported297_withIdC(imported216_taggedContentC(imported89_mediaC))),
  roadshows: t.readonlyArray(imported298_withIdC(imported217_taggedContentC(imported33_roadShowDataC))),
  participants: t.readonlyArray(imported175_hasManyLinkC(imported299_withIdC(imported1_offeringParticipantWithTypeC), imported19_relatedOfferingParticipantLinkC))
});
export type InvestorPortalOfferingC = typeof investorPortalOfferingC;
export type InvestorPortalOffering = t.TypeOf<InvestorPortalOfferingC>;


