import * as t from "io-ts";
import { mediaC as imported54_mediaC, mediaC as imported55_mediaC, mediaC as imported56_mediaC } from "./media";
import { InvestorFocusCU as imported4_InvestorFocusCU, investorFocusOrd as imported3_investorFocusOrd } from "../domaintables/investorFocuses";
import { bLConfigC as imported2_bLConfigC } from "./blConfig";
import { relatedIssuerWithIssuerC as imported5_relatedIssuerWithIssuerC } from "./relatedIssuer";
import { DisclaimerTypeCU as imported2_DisclaimerTypeCU } from "../domaintables/disclaimerTypes";
import { customPagesC as imported1_customPagesC } from "./customPages";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { addressC as imported9_addressC } from "./address";
import { hrefC as imported3_hrefC } from "./href";
import { withIdC as imported207_withIdC, WithStatusCU as imported237_WithStatusCU, WithStatusCU as imported242_WithStatusCU, WithStatusCU as imported245_WithStatusCU, WithStatusCU as imported238_WithStatusCU, WithStatusCU as imported243_WithStatusCU, WithStatusCU as imported235_WithStatusCU, WithStatusCU as imported244_WithStatusCU, WithStatusCU as imported240_WithStatusCU, WithStatusCU as imported239_WithStatusCU, withIdC as imported208_withIdC, WithStatusCU as imported236_WithStatusCU, WithStatusCU as imported241_WithStatusCU } from "./threadThrough";
import { issuerC as imported37_issuerC, issuerC as imported38_issuerC, issuerC as imported39_issuerC } from "./issuer";
import { issuerPreferencesC as imported1_issuerPreferencesC } from "./issuerPreferences";
import { readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import { siteTemplateC as imported4_siteTemplateC, siteTemplateC as imported5_siteTemplateC } from "./siteTemplate";
import { pageSortC as imported5_pageSortC } from "./pageSorts";
import { usersIssuerC as imported2_usersIssuerC, usersIssuerC as imported3_usersIssuerC } from "./usersIssuer";
import { documentTypeC as imported9_documentTypeC } from "./document";
import { LinkableCU as imported1_LinkableCU, pageLinkC as imported1_pageLinkC } from "./linkable";
import { flashC as imported9_flashC, flashC as imported10_flashC, flashC as imported7_flashC, flashC as imported11_flashC, flashC as imported8_flashC, flashC as imported6_flashC } from "./flash";
import { disclaimerC as imported5_disclaimerC, disclaimerC as imported6_disclaimerC, disclaimerC as imported7_disclaimerC } from "./disclaimer";
import { bankC as imported11_bankC, bankC as imported12_bankC, bankC as imported13_bankC, bankC as imported14_bankC } from "./bank";
import { readonlySetFromArray } from "io-ts-types/lib/readonlySetFromArray";
import { pageConfigC as imported2_pageConfigC, pageConfigC as imported3_pageConfigC } from "./pageConfig";
import { userWithRolesC as imported9_userWithRolesC, userWithRolesC as imported7_userWithRolesC, userWithRolesC as imported6_userWithRolesC, userWithRolesC as imported10_userWithRolesC, userWithRolesC as imported11_userWithRolesC, userWithRolesC as imported8_userWithRolesC } from "./user";
import { PageCU as imported4_PageCU, TitleEditableCU as imported3_TitleEditableCU, IntroSentenceEditableCU as imported3_IntroSentenceEditableCU, SortableCU as imported3_SortableCU, PhotoEditableCU as imported9_PhotoEditableCU, PhotoEditableCU as imported8_PhotoEditableCU, PageCU as imported5_PageCU, PhotoEditableCU as imported10_PhotoEditableCU } from "../domaintables/pages";
import { SectorCU as imported13_SectorCU, sectorOrd as imported3_sectorOrd } from "../domaintables/sectors";
import { readonlyMapFromEntries } from "io-ts-types/lib/readonlyMapFromEntries";
import { Ord as numberOrd } from "fp-ts/lib/number";
import { officerC as imported7_officerC } from "./officer";
import { clientFeatureFlagsC as imported9_clientFeatureFlagsC, clientFeatureFlagsC as imported8_clientFeatureFlagsC, clientFeatureFlagsC as imported6_clientFeatureFlagsC, clientFeatureFlagsC as imported11_clientFeatureFlagsC, clientFeatureFlagsC as imported10_clientFeatureFlagsC, clientFeatureFlagsC as imported7_clientFeatureFlagsC } from "./clientFeatureFlags";
import { markdownC } from "../../codecs/markdown";

export class bLWindowCC<A1 extends t.Mixed>{ codec = (A1: A1) => t.type({
  BLSession: A1,
  BLConfig: imported2_bLConfigC
})}
export const bLWindowC = <A1 extends t.Mixed>(A1: A1) => new bLWindowCC<A1>().codec(A1);
export type BLWindowC<A1 extends t.Mixed> = ReturnType<bLWindowCC<A1>["codec"]>;
export type BLWindow<A1> = t.TypeOf<BLWindowC<t.Type<A1>>>;

export const bLBaseSessionC = t.type({
  flash: t.readonlyArray(imported6_flashC),
  user: optionFromNullable(imported6_userWithRolesC)
});
export type BLBaseSessionC = typeof bLBaseSessionC;
export type BLBaseSession = t.TypeOf<BLBaseSessionC>;


export const bLDealPortalSessionC = t.type({
  flash: t.readonlyArray(imported7_flashC),
  user: imported7_userWithRolesC,
  bank: imported11_bankC,
  issuer: optionFromNullable(imported37_issuerC),
  usersIssuers: t.readonlyArray(imported207_withIdC(imported2_usersIssuerC)),
  bankFeatureFlags: imported6_clientFeatureFlagsC,
  issuerFeatureFlags: readonlyMapFromEntries(t.number, numberOrd, imported7_clientFeatureFlagsC),
  issuerPages: readonlyMapFromEntries(t.number, numberOrd, t.readonlyArray(imported2_pageConfigC(imported4_PageCU)))
});
export type BLDealPortalSessionC = typeof bLDealPortalSessionC;
export type BLDealPortalSession = t.TypeOf<BLDealPortalSessionC>;


export const bLBankSitesSessionC = t.type({
  flash: t.readonlyArray(imported8_flashC),
  bank: imported12_bankC,
  user: optionFromNullable(imported8_userWithRolesC),
  siteTemplate: optionFromNullable(imported235_WithStatusCU(imported4_siteTemplateC)),
  headerPhotos: t.readonlyArray(t.tuple([imported8_PhotoEditableCU, imported236_WithStatusCU(imported54_mediaC)])),
  featureFlags: imported8_clientFeatureFlagsC
});
export type BLBankSitesSessionC = typeof bLBankSitesSessionC;
export type BLBankSitesSession = t.TypeOf<BLBankSitesSessionC>;


export const bLIssuerSitesSessionC = t.type({
  flash: t.readonlyArray(imported9_flashC),
  issuer: imported38_issuerC,
  iffs: imported9_clientFeatureFlagsC,
  bank: optionFromNullable(imported13_bankC),
  bankFeatureFlags: imported10_clientFeatureFlagsC,
  primaryOfficer: optionFromNullable(imported237_WithStatusCU(imported7_officerC)),
  docTypes: t.readonlyArray(imported9_documentTypeC),
  user: optionFromNullable(imported9_userWithRolesC),
  userFocuses: readonlySetFromArray(imported4_InvestorFocusCU, imported3_investorFocusOrd),
  userSectors: readonlySetFromArray(imported13_SectorCU, imported3_sectorOrd),
  userSubscribed: t.boolean,
  headerPhotos: t.readonlyArray(t.tuple([imported9_PhotoEditableCU, imported238_WithStatusCU(imported55_mediaC)])),
  disclaimers: t.readonlyArray(imported239_WithStatusCU(imported5_disclaimerC)),
  footerDisclaimerTypes: t.readonlyArray(imported2_DisclaimerTypeCU),
  exitDisclaimer: optionFromNullable(imported240_WithStatusCU(imported6_disclaimerC)),
  dueDisclaimer: t.readonlyArray(t.tuple([t.string, imported241_WithStatusCU(imported7_disclaimerC)])),
  footerLinks: t.readonlyArray(imported242_WithStatusCU(imported3_hrefC)),
  siteTemplate: optionFromNullable(imported243_WithStatusCU(imported5_siteTemplateC)),
  relatedIssuers: t.readonlyArray(imported244_WithStatusCU(imported5_relatedIssuerWithIssuerC)),
  prefs: optionFromNullable(imported1_issuerPreferencesC),
  navLinks: readonlyNonEmptyArrayC(t.tuple([imported1_pageLinkC, t.readonlyArray(imported1_LinkableCU)])),
  pages: t.readonlyArray(imported3_pageConfigC(imported5_PageCU))
});
export type BLIssuerSitesSessionC = typeof bLIssuerSitesSessionC;
export type BLIssuerSitesSession = t.TypeOf<BLIssuerSitesSessionC>;


export const bLIssuerPortalSessionC = t.type({
  flash: t.readonlyArray(imported10_flashC),
  customPages: imported1_customPagesC,
  issuer: imported39_issuerC,
  bank: optionFromNullable(imported14_bankC),
  address: optionFromNullable(imported9_addressC),
  user: imported10_userWithRolesC,
  usersIssuers: t.readonlyArray(imported208_withIdC(imported3_usersIssuerC)),
  iffs: imported11_clientFeatureFlagsC,
  openDraftCount: t.number,
  headerPhotos: t.readonlyArray(t.tuple([imported10_PhotoEditableCU, imported245_WithStatusCU(imported56_mediaC)])),
  introSentences: t.readonlyArray(t.tuple([imported3_IntroSentenceEditableCU, markdownC])),
  pageTitles: t.readonlyArray(t.tuple([imported3_TitleEditableCU, t.string])),
  pageSorts: t.readonlyArray(t.tuple([imported3_SortableCU, imported5_pageSortC]))
});
export type BLIssuerPortalSessionC = typeof bLIssuerPortalSessionC;
export type BLIssuerPortalSession = t.TypeOf<BLIssuerPortalSessionC>;


export const bLInvestorPortalSessionC = t.type({
  flash: t.readonlyArray(imported11_flashC),
  user: imported11_userWithRolesC
});
export type BLInvestorPortalSessionC = typeof bLInvestorPortalSessionC;
export type BLInvestorPortalSession = t.TypeOf<BLInvestorPortalSessionC>;


