import { WithStatusCU as imported304_WithStatusCU, WithStatusCU as imported302_WithStatusCU, WithStatusCU as imported303_WithStatusCU } from "./threadThrough";
import * as t from "io-ts";
import { mediaC as imported63_mediaC, mediaUploadResponseC as imported19_mediaUploadResponseC } from "./media";
import { dataAndIdC as imported7_dataAndIdC, dataAndIdC as imported8_dataAndIdC } from "./relatedContent";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { BidSubmissionTemplateDetailStateCU as imported3_BidSubmissionTemplateDetailStateCU, BidSubmissionTemplateDetailStateCU as imported4_BidSubmissionTemplateDetailStateCU, BidSubmissionTemplateDetailStateCU as imported5_BidSubmissionTemplateDetailStateCU } from "../domaintables/bidSubmissionTemplateDetailsState";

export const bidSubmissionTemplateC = t.type({
  interestRate: imported3_BidSubmissionTemplateDetailStateCU,
  fees: imported4_BidSubmissionTemplateDetailStateCU,
  additionalComments: imported5_BidSubmissionTemplateDetailStateCU,
  introductionText: optionFromNullable(t.string)
});
export type BidSubmissionTemplateC = typeof bidSubmissionTemplateC;
export type BidSubmissionTemplate = t.TypeOf<BidSubmissionTemplateC>;


export const bidSubmissionTemplateQuestionC = t.type({
  order: t.number,
  bidSubmissionTemplateId: t.number,
  required: t.boolean,
  yesOrNo: t.boolean,
  questionText: t.string
});
export type BidSubmissionTemplateQuestionC = typeof bidSubmissionTemplateQuestionC;
export type BidSubmissionTemplateQuestion = t.TypeOf<BidSubmissionTemplateQuestionC>;


export const bidSubmissionTemplateDocumentC = t.type({
  order: t.number,
  bidSubmissionTemplateId: t.number,
  documentTemplateId: optionFromNullable(t.number),
  name: t.string,
  required: t.boolean
});
export type BidSubmissionTemplateDocumentC = typeof bidSubmissionTemplateDocumentC;
export type BidSubmissionTemplateDocument = t.TypeOf<BidSubmissionTemplateDocumentC>;


export const bidSubmissionTemplateDocumentDataC = t.type({
  bidSubmissionTemplateDocument: bidSubmissionTemplateDocumentC,
  documentTemplate: optionFromNullable(imported304_WithStatusCU(imported63_mediaC))
});
export type BidSubmissionTemplateDocumentDataC = typeof bidSubmissionTemplateDocumentDataC;
export type BidSubmissionTemplateDocumentData = t.TypeOf<BidSubmissionTemplateDocumentDataC>;


export const bidSubmissionTemplateDataC = t.type({
  bidSubmissionTemplate: bidSubmissionTemplateC,
  questions: t.readonlyArray(imported302_WithStatusCU(bidSubmissionTemplateQuestionC)),
  documents: t.readonlyArray(imported303_WithStatusCU(bidSubmissionTemplateDocumentDataC))
});
export type BidSubmissionTemplateDataC = typeof bidSubmissionTemplateDataC;
export type BidSubmissionTemplateData = t.TypeOf<BidSubmissionTemplateDataC>;


export const bidSubmissionTemplateDocumentPostC = t.type({
  order: t.number,
  documentTemplate: optionFromNullable(imported19_mediaUploadResponseC),
  name: t.string,
  required: t.boolean
});
export type BidSubmissionTemplateDocumentPostC = typeof bidSubmissionTemplateDocumentPostC;
export type BidSubmissionTemplateDocumentPost = t.TypeOf<BidSubmissionTemplateDocumentPostC>;


export const bidSubmissionTemplateQuestionPostC = t.type({
  order: t.number,
  required: t.boolean,
  yesOrNo: t.boolean,
  questionText: t.string
});
export type BidSubmissionTemplateQuestionPostC = typeof bidSubmissionTemplateQuestionPostC;
export type BidSubmissionTemplateQuestionPost = t.TypeOf<BidSubmissionTemplateQuestionPostC>;


export const bidSubmissionTemplatePostRelatedContentC = t.type({
  documents: t.readonlyArray(imported7_dataAndIdC(bidSubmissionTemplateDocumentPostC)),
  questions: t.readonlyArray(imported8_dataAndIdC(bidSubmissionTemplateQuestionPostC))
});
export type BidSubmissionTemplatePostRelatedContentC = typeof bidSubmissionTemplatePostRelatedContentC;
export type BidSubmissionTemplatePostRelatedContent = t.TypeOf<BidSubmissionTemplatePostRelatedContentC>;


export const bidSubmissionTemplatePostC = t.type({
  interestRate: t.number,
  fees: t.number,
  additionalComments: t.number,
  introductionText: optionFromNullable(t.string),
  relatedContent: bidSubmissionTemplatePostRelatedContentC
});
export type BidSubmissionTemplatePostC = typeof bidSubmissionTemplatePostC;
export type BidSubmissionTemplatePost = t.TypeOf<BidSubmissionTemplatePostC>;


