import { withIdC as imported198_withIdC, WithStatusCU as imported211_WithStatusCU, WithStatusCU as imported213_WithStatusCU, hasManyLinkC as imported89_hasManyLinkC, WithStatusCU as imported212_WithStatusCU, hasManyLinkC as imported88_hasManyLinkC, withIdC as imported196_withIdC, WithStatusCU as imported215_WithStatusCU, WithStatusCU as imported214_WithStatusCU, WithStatusCU as imported207_WithStatusCU, WithStatusCU as imported209_WithStatusCU, WithStatusCU as imported208_WithStatusCU, WithStatusCU as imported210_WithStatusCU, withIdC as imported197_withIdC, hasManyLinkC as imported90_hasManyLinkC } from "./threadThrough";
import { readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { issuerRatingC as imported8_issuerRatingC, bondRatingC as imported2_bondRatingC, programRatingC as imported9_programRatingC, issuerRatingC as imported9_issuerRatingC, programRatingC as imported10_programRatingC, programRatingC as imported8_programRatingC, issuerRatingC as imported10_issuerRatingC } from "./ratingBase";
import { RatingAgencyCU as imported26_RatingAgencyCU, RatingAgencyCU as imported29_RatingAgencyCU, RatingAgencyCU as imported28_RatingAgencyCU, RatingAgencyCU as imported24_RatingAgencyCU, RatingAgencyCU as imported30_RatingAgencyCU, RatingAgencyCU as imported27_RatingAgencyCU, RatingAgencyCU as imported25_RatingAgencyCU } from "../domaintables/ratingAgencies";
import { taggedContentC as imported122_taggedContentC } from "./taggedContent";
import { bondProgramC as imported11_bondProgramC, bondProgramC as imported12_bondProgramC, bondProgramC as imported13_bondProgramC } from "./bondProgramBase";
import { analystC as imported3_analystC } from "./analyst";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { sortOrderC as imported19_sortOrderC } from "./sortOrder";
import { relatedAnalystLinkC as imported3_relatedAnalystLinkC, relatedAnalystLinkC as imported2_relatedAnalystLinkC, relatedDocumentLinkC as imported18_relatedDocumentLinkC, isAttachedC as imported11_isAttachedC, postRelatedAndAttachedC as imported7_postRelatedAndAttachedC, postRelatedC as imported32_postRelatedC, relatedDocumentLinkC as imported19_relatedDocumentLinkC, relatedRatingLinkC as imported2_relatedRatingLinkC } from "./relatedContent";
import { markdownC } from "../../codecs/markdown";
import { mediaC as imported46_mediaC, mediaUploadResponseWithCategoryC as imported7_mediaUploadResponseWithCategoryC } from "./media";
import { LocalDateC } from "../../codecs/localDate";

export const ratingRelatedContentC = t.type({
  documents: t.readonlyArray(imported88_hasManyLinkC(imported207_WithStatusCU(imported122_taggedContentC(imported46_mediaC)), imported11_isAttachedC(imported18_relatedDocumentLinkC))),
  analysts: t.readonlyArray(imported89_hasManyLinkC(imported208_WithStatusCU(imported3_analystC), imported2_relatedAnalystLinkC))
});
export type RatingRelatedContentC = typeof ratingRelatedContentC;
export type RatingRelatedContent = t.TypeOf<RatingRelatedContentC>;


export class ratingWithRelatedContentCC<A1 extends t.Mixed>{ codec = (A1: A1) => t.type({
  rating: A1,
  relatedContent: ratingRelatedContentC
})}
export const ratingWithRelatedContentC = <A1 extends t.Mixed>(A1: A1) => new ratingWithRelatedContentCC<A1>().codec(A1);
export type RatingWithRelatedContentC<A1 extends t.Mixed> = ReturnType<ratingWithRelatedContentCC<A1>["codec"]>;
export type RatingWithRelatedContent<A1> = t.TypeOf<RatingWithRelatedContentC<t.Type<A1>>>;

export const issuerRatingsC = t.readonlyArray(t.tuple([imported24_RatingAgencyCU, readonlyNonEmptyArrayC(imported209_WithStatusCU(imported8_issuerRatingC))]));
export type IssuerRatingsC = typeof issuerRatingsC;
export type IssuerRatings = t.TypeOf<IssuerRatingsC>;


export const issuerRatingsWithRelatedContentC = t.readonlyArray(t.tuple([imported25_RatingAgencyCU, readonlyNonEmptyArrayC(imported210_WithStatusCU(ratingWithRelatedContentC(imported9_issuerRatingC)))]));
export type IssuerRatingsWithRelatedContentC = typeof issuerRatingsWithRelatedContentC;
export type IssuerRatingsWithRelatedContent = t.TypeOf<IssuerRatingsWithRelatedContentC>;


export const programRatingsC = t.readonlyArray(t.tuple([imported196_withIdC(imported11_bondProgramC), readonlyNonEmptyArrayC(t.tuple([imported26_RatingAgencyCU, readonlyNonEmptyArrayC(imported211_WithStatusCU(imported8_programRatingC))]))]));
export type ProgramRatingsC = typeof programRatingsC;
export type ProgramRatings = t.TypeOf<ProgramRatingsC>;


export const programRatingsWithRelatedContentC = t.readonlyArray(t.tuple([imported197_withIdC(imported12_bondProgramC), readonlyNonEmptyArrayC(t.tuple([imported27_RatingAgencyCU, readonlyNonEmptyArrayC(imported212_WithStatusCU(ratingWithRelatedContentC(imported9_programRatingC)))]))]));
export type ProgramRatingsWithRelatedContentC = typeof programRatingsWithRelatedContentC;
export type ProgramRatingsWithRelatedContent = t.TypeOf<ProgramRatingsWithRelatedContentC>;


export const bondRatingsC = t.readonlyArray(t.tuple([imported28_RatingAgencyCU, readonlyNonEmptyArrayC(imported213_WithStatusCU(ratingWithRelatedContentC(imported90_hasManyLinkC(imported2_bondRatingC, imported2_relatedRatingLinkC))))]));
export type BondRatingsC = typeof bondRatingsC;
export type BondRatings = t.TypeOf<BondRatingsC>;


export const ratingPostRelatedContentC = t.type({
  documents: imported7_postRelatedAndAttachedC(imported19_relatedDocumentLinkC, imported7_mediaUploadResponseWithCategoryC, imported19_sortOrderC),
  analysts: imported32_postRelatedC(imported3_relatedAnalystLinkC)
});
export type RatingPostRelatedContentC = typeof ratingPostRelatedContentC;
export type RatingPostRelatedContent = t.TypeOf<RatingPostRelatedContentC>;


export const attachedRatingPostBodyC = t.type({
  rating: t.string,
  ratingAgencyId: t.number,
  ratingDate: LocalDateC,
  ratingNotes: optionFromNullable(markdownC),
  ratingOutlookId: t.number,
  relatedContent: ratingPostRelatedContentC
});
export type AttachedRatingPostBodyC = typeof attachedRatingPostBodyC;
export type AttachedRatingPostBody = t.TypeOf<AttachedRatingPostBodyC>;


export const issuerAndProgramRatingsByAgencyC = t.type({
  issuerRatings: t.readonlyArray(t.tuple([imported29_RatingAgencyCU, readonlyNonEmptyArrayC(imported214_WithStatusCU(ratingWithRelatedContentC(imported10_issuerRatingC)))])),
  programRatings: t.readonlyArray(t.tuple([imported198_withIdC(imported13_bondProgramC), readonlyNonEmptyArrayC(t.tuple([imported30_RatingAgencyCU, readonlyNonEmptyArrayC(imported215_WithStatusCU(ratingWithRelatedContentC(imported10_programRatingC)))]))]))
});
export type IssuerAndProgramRatingsByAgencyC = typeof issuerAndProgramRatingsByAgencyC;
export type IssuerAndProgramRatingsByAgency = t.TypeOf<IssuerAndProgramRatingsByAgencyC>;


export const issuerRatingPostBodyC = t.type({
  id: optionFromNullable(t.number),
  rating: t.string,
  ratingAgencyId: t.number,
  ratingDate: LocalDateC,
  ratingNotes: optionFromNullable(markdownC),
  ratingOutlookId: t.number,
  relatedContent: ratingPostRelatedContentC
});
export type IssuerRatingPostBodyC = typeof issuerRatingPostBodyC;
export type IssuerRatingPostBody = t.TypeOf<IssuerRatingPostBodyC>;


export const programRatingPostBodyC = t.type({
  id: optionFromNullable(t.number),
  bondProgramId: t.number,
  rating: t.string,
  ratingAgencyId: t.number,
  ratingDate: LocalDateC,
  ratingNotes: optionFromNullable(markdownC),
  ratingOutlookId: t.number,
  relatedContent: ratingPostRelatedContentC
});
export type ProgramRatingPostBodyC = typeof programRatingPostBodyC;
export type ProgramRatingPostBody = t.TypeOf<ProgramRatingPostBodyC>;


